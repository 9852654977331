'use client';
import React from 'react';
import { ThemeVarsProps } from '@atlas/statics/lib/types/variables';
import { Country } from '@atlas/ui-library/components/organisms/CountryCards/types';

type Price = {
	max: { amount: number; currency: string };
};

type Prices = {
	service_fee: Price;
};

export type ProductType = {
	availableLanguages: { locale: string; name: string }[];
	eligibleCountries: Country[];
	emailFromName: string;
	freshchatCode?: string;
	slaMax: number;
	slaMin: number;
};

export type Site = {
	companyAddress: string;
	companyCif: string;
	companyName: string;
	companyPhone: string;
	googleTagManager: string;
	infoEmail: string;
	morAddress: string;
	morCif: string;
	morName: string;
	morRegistry: any;
	prices: Prices;
	site_url: string;
	sitePhone: string;
	url: string;
	visaEmail: string;
};

export interface CrmData {
	productType: ProductType;
	site: Site;
}

export interface AppContextProps {
	hostUrl: string;
	themeVars: ThemeVarsProps;
}

export const AppContext = React.createContext({} as AppContextProps);

export default AppContext;
