import { EXCLUDED_PROPERTIES } from '@atlas/statics/lib/getDynamicVarsMap/config';

export const findValueInObjectProperties = (
	targetObject: { [key: string]: any } | string,
	propertyName: string,
): string => {
	let foundValue = '';
	if (typeof targetObject === 'object') {
		const propertyPath = propertyName.split('.');
		if (propertyPath.length > 1) {
			foundValue = getObjectPropertyByPath(
				targetObject,
				propertyPath,
			); // 🔍
		} else {
			Object.values(targetObject).forEach((propertyValue) => {
				if (!EXCLUDED_PROPERTIES.includes(propertyValue)) {
					const result =
						searchPropertyValue(
							propertyValue,
							propertyName,
						) ?? ''; // 🔍
					if (result !== '') foundValue = result; // 🏆
				}
			});
		}
	}
	return foundValue;
};

export const getObjectPropertyByPath = (
	targetObject: { [key: string]: any } | string,
	propertyPath: string[],
): string => {
	if (typeof targetObject !== 'object' || targetObject === null)
		return '';
	let currentObj = targetObject;
	for (let i = 0; i < propertyPath.length; i++) {
		if (currentObj === undefined || currentObj === null) {
			return '';
		}
		currentObj = Array.isArray(currentObj)
			? currentObj[0][propertyPath[i]]
			: currentObj[propertyPath[i]];
	}
	return currentObj.toString() || '';
};

export const searchPropertyValue = (
	targetObject: any,
	propertyName: string,
): string => {
	if (!targetObject) return ''; // ❌ no obj, no search.
	if (targetObject[propertyName]) return targetObject[propertyName]; // 🏆 win!
	return findValueInObjectProperties(targetObject, propertyName); // 🔍 not here, keep digging.
};
