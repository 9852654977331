import { FILTER_COLLECTION, FILTER_DELIMITER } from './config';
import type { getDynamicVarsMapProps } from './types';
import type { Filter } from '@atlas/statics/lib/replacePageDataVariables/types';
import { applySubdomainFilter } from './filters/subdomain';
import { applyImageFilter } from './filters/image';
import { applyForceUrlFilter } from './filters/forceUrl';
import { searchPropertyValue } from '@atlas/statics/lib/searchPropertyValue';

export const removeCurlyBraces = (variable: string): string =>
	variable.slice(2, -2);

export const getVarName = (variable: string): string => {
	const regex = new RegExp(
		`\\s*${FILTER_DELIMITER}\\s*(${FILTER_COLLECTION.join('|')})`,
	);
	return removeCurlyBraces(variable).split(regex)[0].trim();
};

export const getFilterName = (variableName: string): Filter | null => {
	const variableNameSplit = variableName.split(',');
	if (variableNameSplit.length <= 1) {
		return null;
	}
	const [, filterName] = variableNameSplit;
	const filterNameTrimmed = filterName.trim() as Filter;
	if (!FILTER_COLLECTION.includes(filterNameTrimmed)) {
		return null;
	}
	return filterNameTrimmed;
};

export const getVarsToReplaceSet = (sourceString: string): string[] => {
	const variableRegex = new RegExp(
		`\\{\\{(\\s*)?([\\w.]+)(?:\\s*${FILTER_DELIMITER}\\s*(${FILTER_COLLECTION.join(
			'|',
		)}))?(\\s*)?\\}\\}`,
		'g',
	);
	return [...new Set(sourceString.match(variableRegex) || [])];
};

export async function getDynamicVarsMap({
	sourceString,
	crmData,
	font,
}: getDynamicVarsMapProps): Promise<Record<string, string>> {
	const variableMap: Record<string, string> = {};
	const variablesToReplace = getVarsToReplaceSet(sourceString);
	const flatCrmData = { ...crmData.productType, ...crmData.site };
	await Promise.all(
		variablesToReplace.map(async (variable) => {
			const variableWithoutCurlyBraces =
				removeCurlyBraces(variable);
			const variableName = getVarName(variable);
			const variableValue = searchPropertyValue(
				flatCrmData,
				getVarName(variable),
			);
			switch (getFilterName(variableWithoutCurlyBraces)) {
				case 'image':
					if (!font) {
						throw new Error(
							'No font provided. Font is required.',
						);
					}
					variableMap[variableWithoutCurlyBraces] =
						await applyImageFilter({ variableValue, font });
					break;
				case 'subdomain':
					variableMap[variableWithoutCurlyBraces] =
						applySubdomainFilter(variableValue);
					break;
				case 'forceUrl':
					variableMap[variableWithoutCurlyBraces] =
						applyForceUrlFilter({
							variableName,
							variableValue,
						});
					break;
				default:
					variableMap[variableWithoutCurlyBraces] =
						variableValue;
					break;
			}
		}),
	).catch((err: unknown) => {
		throw new Error(
			`Function buildDynamicVariablesMap() failed parsing '${sourceString}'.`,
			{
				cause: err,
			},
		);
	});
	return variableMap;
}
