import React from 'react';
import { ThemeProvider } from 'styled-components';
import { MenuNavList, NavbarSC, MenuNavElement } from './style';
import { Body } from '@atlas/ui-library/components/atoms/FontScale/Body';
import { Wrapper } from '@atlas/ui-library/components/layout/Wrapper';
import { NavbarProps } from './types';
import Link from 'next/link';
import { usePathname } from 'next/navigation';
import clsx from 'clsx';
import { useLocale } from 'next-intl';
import LocaleSwitcher from '@atlas/ui-library/components/molecules/LocaleSwitcher';
import { useWindowWidth } from '@atlas/ui-library/hooks/useWindowWidth';
import { isMobile } from '@atlas/ui-library/lib/isMobile';
import { useCrmData } from '@atlas/statics/hooks/useCrmData';
import { Skeleton } from '@atlas/ui-library/components/layout/Skeleton';
import { NavbarSkeleton } from './skeleton';
import { isAbsoluteUrl } from '@atlas/ui-library/lib/isAbsoluteUrl';

/**
 * Component for showing Navbar.
 * @component
 * @param variant currently navbar has only DEFAULT variant
 * @param nav {Array} Array of objects each with: href {string}, title {string} and target {boolean}
 * @param languages {Boolean} Toggles language selector.
 * @example
 *   <Navbar variant="DEFAULT" nav=[{title: 'Contact', href: '/contact', target: true}] languages="false" />
 */
export const Navbar = ({
	nav,
	variant,
	isLoading = false,
}: NavbarProps) => {
	const { data: crmData } = useCrmData();
	if (!crmData) {
		throw new Error('CRM data not found');
	}
	const pathname = usePathname();
	const locale = useLocale();
	const windowWidth = useWindowWidth();
	const isMobileView = isMobile(windowWidth);
	const {
		productType: { availableLanguages },
	} = crmData;
	const hasLocales = availableLanguages.length > 1;

	const isCurrentPage = (href: string) => {
		return pathname === (href.endsWith('/') ? href : `${href}/`);
	};

	if (isLoading)
		return (
			<Skeleton
				renderSkeleton={() => (
					<NavbarSkeleton hasLanguageSelector={hasLocales} />
				)}
			/>
		);

	return (
		<ThemeProvider theme={{ variant }}>
			<NavbarSC>
				<Wrapper direction="">
					<MenuNavList>
						{nav?.map(
							({ href, body: { text }, target }) => {
								href = isAbsoluteUrl(href)
									? href
									: `/${locale}${href}`;
								return (
									<MenuNavElement key={text}>
										<Link
											href={href}
											target={
												target
													? '_blank'
													: '_self'
											}
											rel="noreferrer"
											className={clsx({
												current:
													isCurrentPage(href),
											})}>
											<Body
												size="L"
												variant="light">
												{text}
											</Body>
										</Link>
									</MenuNavElement>
								);
							},
						)}
						{hasLocales && !isMobileView && (
							<MenuNavElement key={'locale-dropdown'}>
								<LocaleSwitcher />
							</MenuNavElement>
						)}
					</MenuNavList>
				</Wrapper>
			</NavbarSC>
		</ThemeProvider>
	);
};
