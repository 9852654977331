import styled from 'styled-components';
import { LinkContent } from '@atlas/ui-library/components/atoms/Link/style';

const LinkContainer = styled.div`
	padding: 12px;
	margin: 12px;
	text-align: center;
	${LinkContent} {
		font-weight: normal;
	}
`;

const NoMatchFound = styled.div`
	display: flex;
	flex-direction: column;
	flex-wrap: nowrap;
	justify-content: center;
	align-items: center;
	padding-bottom: 12px;
	width: 100%;
	font-family: var(--font-body-family-regular);
	font-size: var(--font-body-size-L-mobile);
`;

const NoMatchFoundTitle = styled.p`
	font-weight: 600;
	color: var(--color-primary-_900);
	margin: 5px;
`;

const NoMatchFoundBody = styled.p`
	font-size: var(--font-body-size-M-mobile);
	font-weight: 400;
	color: var(--color-neutral-_900);
	margin: 5px;
`;

const WrapperCountryCards = styled.div`
	margin: 0 auto;
	text-align: center;
	width: 100%;
`;

const CountryCardsContainer = styled.div<{ $isCollapsed: boolean }>`
	height: ${(props) => (props.$isCollapsed ? 'auto' : '483px')};
	display: flex;
	flex-direction: row;
	flex-wrap: wrap;
	justify-content: flex-start;
	align-items: stretch;
	gap: 14px;
	overflow-y: clip;
	margin: unset;
	padding: 24px 0px;
`;

const BlurContainer = styled.div`
	padding: 12px;
	position: relative;
	&:before {
		content: '';
		display: inline-block;
		position: absolute;
		bottom: 100%;
		left: 0px;
		width: 100%;
		height: 150px;
		background: linear-gradient(
			rgba(255, 255, 255, 0),
			var(--color-basic-white)
		);
	}
`;

export {
	LinkContainer,
	NoMatchFound,
	WrapperCountryCards,
	BlurContainer,
	CountryCardsContainer,
	NoMatchFoundTitle,
	NoMatchFoundBody,
};
