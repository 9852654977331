import styled, { css } from 'styled-components';
import theme from 'styled-theming';
import { TitleContainer } from '@atlas/ui-library/components/atoms/FontScale/Title/style';
import { media } from '@atlas/ui-library/lib/breakpoints';

const verticalAlign = theme('verticalAlign', {
	start: css`
		align-items: flex-start;
	`,
	center: css`
		align-items: center;
	`,
	end: css`
		align-items: flex-end;
	`,
});

const blockVariants = theme('direction', {
	row: css`
		flex-direction: row;
	`,
	column: css`
		flex-direction: column;
	`,
});

const borderOptions = theme('border', {
	true: css`
		border: 1px solid var(--color-neutral-_100);
	`,
	false: css`
		border: none;
	`,
});

const themeVariants = theme('variant', {
	default: css``,
	turkey2Advantages: css`
		display: flex;
		flex-wrap: nowrap;
		align-items: flex-start;
		.ContainerInfoBlock {
			border: 1px solid var(--color-neutral-_100);
			h1,
			h2,
			h3,
			h4 {
				display: flex;
				align-items: center;
				width: 100%;
				margin: 0;
				padding: var(--spacing-_16) var(--spacing-_8);
				background-color: var(--color-neutral-_100);
				position: relative;
				padding-left: calc(52px + var(--spacing-_8));
				font-size: 17px;
				font-weight: bold;
				color: var(--color-neutral-_900);
				overflow: hidden;
				&:before {
					content: '';
					position: absolute;
					display: block;
					height: 100%;
					width: 52px;
					background-color: var(--color-error-_100);
					top: 0;
					left: 0;
					bottom: 0;
				}
			}
			> div {
				padding: var(--spacing-_16);
				font-size: 14px;
			}

			&:nth-of-type(1) {
				h1,
				h2,
				h3,
				h4 {
					&:before {
						background:
							url('/turkey/turkey2/fa-check.svg') center
								center no-repeat,
							var(--color-error-_100);
						background-size: 24px 24px;
					}
				}
			}
			&:nth-of-type(2) {
				h1,
				h2,
				h3,
				h4 {
					&:before {
						background:
							url('/turkey/turkey2/fa-lock.svg') center
								center no-repeat,
							var(--color-error-_100);
						background-size: 24px 24px;
					}
				}
			}
			&:nth-of-type(3) {
				h1,
				h2,
				h3,
				h4 {
					&:before {
						background:
							url('/turkey/turkey2/fa-try.svg') center
								center no-repeat,
							var(--color-error-_100);
						background-size: 24px 24px;
					}
				}
			}

			${media.smMax`
				margin-bottom: var(--spacing-_24);
				&:last-of-type {
					margin-bottom: 0;
				}	
			`}
		}
	`,
});

const WrapperInfoBlock = styled.div`
	display: flex;
	flex-direction: column;
	justify-content: space-between;
	gap: var(--spacing-_16);
	align-items: center;
	flex-wrap: nowrap;
	${media.mdMin`
		flex-direction: row;
	`}

	${themeVariants}
	${borderOptions}

	// NOTE: When multiple Infoblocks -> Add gap between them.
	& + .WrapperInfoBlock {
		margin-top: var(--spacing-_40);
	}
`;

const ContainerInfoBlock = styled.div`
	flex-direction: column;
	gap: 15px;
	width: 100%;
	text-align: left;
	display: flex;
	flex: 1 1 0;
	align-items: center;
	${TitleContainer} {
		align-self: flex-start;
	}
	a {
		margin-left: 0;
		margin-right: 0;
		${media.mdMin`
			margin-right: auto;
		`}
	}
	a:last-child {
		margin-top: var(--spacing-_16);
		margin-bottom: 0;
	}
	div p:last-child {
		margin-bottom: 0;
	}

	${blockVariants}
	${verticalAlign}
`;

export { WrapperInfoBlock, ContainerInfoBlock };
