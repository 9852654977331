import React from 'react';
import {
	AtomsMapper,
	AtomsMapperProps,
} from '@atlas/ui-library/components';
import { ThemeProvider } from 'styled-components';
import capitalize from '@atlas/ui-library/lib/capitalize';
import { WrapperInfoBlock, ContainerInfoBlock } from './style';
import { Elements } from '@atlas/ui-library/components/layout/Section/types';
import { InfoBlockProps } from './types';
import { useInfoBlock } from './useInfoBlock';
import { Skeleton } from '@atlas/ui-library/components/layout/Skeleton';
import InfoBlockSkeleton from './skeleton';

/**
 * Component to build dynamic blocks of information.
 * @component
 * @param content Props container.
 * @param content.add_element Element information object.
 * @returns Infoblock render.
 */
export const InfoBlock = ({
	variant,
	border,
	add_element = [],
	isLoading = false,
}: InfoBlockProps) => {
	const elements = useInfoBlock({ add_element, variant });

	if (isLoading) {
		return (
			<Skeleton
				renderSkeleton={() => (
					<InfoBlockSkeleton
						variant={variant}
						columns={add_element.length}
					/>
				)}
			/>
		);
	}

	return (
		<ThemeProvider theme={{ variant, border }}>
			<WrapperInfoBlock className="WrapperInfoBlock">
				{elements.map(
					(
						{ element, direction, verticalAlign },
						index: number,
					) => {
						const [{ type }] = element;
						const key = `infoBlock-id-${type}-${index}`;
						return (
							<ThemeProvider
								theme={{ direction, verticalAlign }}
								key={key}>
								<ContainerInfoBlock className="ContainerInfoBlock">
									{element.map((atom: Elements) => {
										const keyComponent: string =
											capitalize(atom.type);
										if (
											keyComponent !== 'Direction'
										) {
											const BlockComponent =
												AtomsMapper[
													keyComponent as keyof AtomsMapperProps
												];
											if (
												keyComponent === 'Image'
											) {
												const {
													alt,
													src,
													title,
													width,
													height,
												} = atom;
												return (
													<BlockComponent
														unoptimized
														key={
															keyComponent
														}
														src={src}
														alt={alt}
														title={title}
														width={width}
														height={height}
													/>
												);
											}
											return (
												<BlockComponent
													key={keyComponent}
													{...atom}
												/>
											);
										}
										return true;
									})}
								</ContainerInfoBlock>
							</ThemeProvider>
						);
					},
				)}
			</WrapperInfoBlock>
		</ThemeProvider>
	);
};

export default InfoBlock;
