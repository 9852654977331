import styled, { css } from 'styled-components';
import theme from 'styled-theming';
import { HEADER_HEIGHT } from '@atlas/ui-library/components/organisms/Header/style';
import { media } from '@atlas/ui-library/lib/breakpoints';
import { BodyContent } from '@atlas/ui-library/components/atoms/FontScale/Body/style';

const footerVariants = theme('variant', {
	DEFAULT: css`
		padding: var(--spacing-_16) 0 0;
		${media.mdMin`
			padding-top: var(--spacing-_48);
		`}
		.FooterNav {
			${media.mdMin`
				width: 43%;
				justify-content: flex-start;
				padding-left: var(--spacing-_16);
			`}

			& > li {
				${media.mdMin`
					width: 50%;
					margin: var(--spacing-_8)
						0;
				`}

				& > a {
					${media.mdMin`
						&:after {
							content: '';
							margin-left: 0;
							color: none;
						}
					`}
				}
			}
		}
	`,
	ONLY_NAV: css`
		padding: 32px 0;
		box-shadow: 0px -8px 15px 0px rgba(67, 69, 139, 0.1);
		.FooterNav {
			width: 100%;
			display: flex;
			flex-wrap: nowrap;
			justify-content: center;
			column-gap: 8px;
			padding: 0;
			margin-bottom: ${HEADER_HEIGHT};

			${media.mdMin`
				flex-direction: row;
				margin-bottom: 0;
			`}

			li {
				display: flex;
				flex-direction: row;
				justify-content: center;
				margin: 0;

				${media.mdMin`
					width: fit-content;
				`}

				a {
					text-align: center;
					color: var(--color-primary-_900);
				}
			}
		}
	`,
});

const SSLIconsLayout = styled.div`
	display: flex;
	flex-direction: column;
	align-content: center;
	padding-bottom: var(--spacing-_24);
	${media.mdMin`
		width: 49%;
		padding-bottom: var(--spacing-_48);
		padding-right: var(--spacing-_16);
	`}
`;

const SSLLayout = styled.div`
	display: flex;
	flex-direction: row;
	align-items: center;
	justify-content: center;
	margin: var(--spacing-_16) var(--spacing-_24);

	${media.mdMin`
		margin: var(--spacing-_16) 0;
		justify-content: flex-start;
	`}

	${BodyContent} {
		margin-left: var(--spacing-_16);
		p {
			margin: 0;
			max-width: 325px;
			color: color-mix(
				in srgb,
				var(--color-text-onSurface)
					var(--opacity-emphasis-surface-medium),
				transparent
			);
			font-size: var(--font-body-size-XS-mobile);
			font-weight: 400;
			line-height: 1.2;
			letter-spacing: 0.6px;
		}
	}
`;

const IconListLayout = styled.ul`
	list-style-type: none;
	padding: 0;
	display: flex;
	flex-wrap: wrap;
	justify-content: center;
	max-width: calc(
		(var(--spacing-_40) * 6) + (var(--spacing-_16) * 6)
	);
	margin: var(--spacing-_8) auto;

	${media.mdMin`
		display: grid;
		grid-template-columns: repeat(8, var(--spacing-_40));
		grid-auto-rows: var(--spacing-_40);
		grid-gap: var(--spacing-_8);
		max-width: none;
		justify-content: unset;
		margin: var(--spacing-_16) 0 0;
	`}

	${media.xlMin`
		grid-template-columns: repeat(11, var(--spacing-_40));
		grid-gap: var(--spacing-_8) var(--spacing-_16);
	`}
`;

const IconLayout = styled.li`
	display: flex;
	align-items: center;
	justify-content: center;
	width: 40px;
	height: 40px;
	margin: var(--spacing-_8);

	span {
		img {
			object-fit: contain;
		}
	}

	${media.mdMin`
		width: auto;
		height: auto;
		margin: 0;
	`}
`;

const LinksListLayout = styled.ul`
	list-style-type: none;
	display: flex;
	flex-direction: row;
	flex-wrap: wrap;
	justify-content: center;
	padding-inline-start: 0;
	margin-block-end: 0;
	padding-bottom: var(--spacing-_48);
	${media.mdMin`
		width: 43%;
		justify-content: flex-start;
		padding-left: var(--spacing-_16);
	`}
`;

const LinkLayout = styled.li`
	width: auto;
	margin: var(--spacing-_8);
	${media.mdMin`
		width: 50%;
		margin: var(--spacing-_8) 0;
	`}
	&:last-child {
		a {
			&:after {
				content: '';
				margin-left: 0;
				color: transparent;
			}
		}
	}
	& > a {
		font-family: var(--font-body-family-regular);
		color: color-mix(
			in srgb,
			var(--color-text-onSurface)
				var(--opacity-emphasis-surface-medium),
			transparent
		);
		text-decoration: none;
		font-size: var(--font-body-size-M-mobile);
		font-weight: 400;
		line-height: 1.3;
		letter-spacing: 0.25px;
		&:after {
			content: '|';
			margin-left: ${({ theme: { variant } }) =>
				variant === 'ONLY_NAV'
					? 'var(--spacing-_8)'
					: 'var(--spacing-_16)'};
			color: color-mix(
				in srgb,
				var(--color-text-onSurface)
					var(--opacity-emphasis-surface-medium),
				transparent
			);
		}
		&:hover {
			text-decoration: underline;
		}

		${media.mdMin`
			&:after {
				content: '';
				margin-left: 0;
				color: transparent;
			}
		`}
	}
`;

const FooterLinks = styled.div``;

const LinksTitle = styled.div``;

const LinksList = styled.ul``;

const LinksLayout = styled.li``;

const FooterSC = styled.div`
	display: flex;
	flex-wrap: wrap;
	text-align: start;
	justify-content: center;
	width: 100% !important;
	${footerVariants}
`;

const FooterDisclaimerSC = styled.div`
	width: 100%;
	text-align: center;
	padding: 0 0 var(--spacing-_24);
	p {
		margin-bottom: 0;
		color: color-mix(
			in srgb,
			var(--color-text-onSurface)
				var(--opacity-emphasis-surface-medium),
			transparent
		);
		text-decoration: none;
		font-size: var(--font-body-size-M-mobile);
		font-weight: 400;
		line-height: 1.3;
		letter-spacing: 0.25px;
	}
`;

export {
	SSLIconsLayout,
	SSLLayout,
	IconListLayout,
	FooterSC,
	FooterDisclaimerSC,
	FooterLinks,
	LinksTitle,
	LinksList,
	LinksLayout,
	IconLayout,
	LinksListLayout,
	LinkLayout,
};
