import { replaceSectionCrmVars } from '@atlas/ui-library/components/layout/Section/ReplaceAllCrmVarsInSection';
import type { Elements } from '@atlas/ui-library/components/layout/Section/types';
import type { CrmData } from '@atlas/statics/services/AppContext';
import type { Font } from '@atlas/statics/lib/types/_fonts';
import { useContext } from 'react';
import { useQuery } from '@tanstack/react-query';
import AppContext from '@atlas/statics/services/AppContext';

type ReplaceAllCrmVarsInSectionProps = {
	elements: Elements[];
	crmData: CrmData | null | undefined;
	font: Font;
};

async function replaceAllCrmVarsInSection({
	elements,
	crmData,
	font,
}: ReplaceAllCrmVarsInSectionProps): Promise<Elements[]> {
	const elementsWithReplacedCrmVars = await Promise.all(
		elements.map(async (element: Elements) => {
			return !crmData
				? element
				: ((await replaceSectionCrmVars({
						element,
						crmData,
						font,
				  })) as Elements);
		}),
	);
	return elementsWithReplacedCrmVars;
}

export function useProcessedElements({
	sectionId,
	crmData,
	elements,
}: {
	sectionId: string;
	crmData: CrmData | null | undefined;
	elements: Elements[];
}) {
	const {
		themeVars: { font },
	} = useContext(AppContext);

	return useQuery({
		queryKey: [
			'processedElements',
			sectionId,
			crmData,
			elements,
			font,
		],
		queryFn: async () => {
			if (!crmData) return elements;
			const processedElements = await replaceAllCrmVarsInSection({
				elements,
				crmData,
				font,
			});
			return processedElements;
		},
		// The query will not execute until the crmData exists
		// https://tanstack.com/query/latest/docs/framework/react/guides/dependent-queries#usequery-dependent-query
		enabled: !!crmData,
	});
}
