import { QueryClient } from '@tanstack/query-core';
import CrmService from '@atlas/statics/services/CrmService';
import { useQuery } from '@tanstack/react-query';

export function useCrmData() {
	return useQuery({
		queryKey: ['crmData'],
		queryFn: () => CrmService.getCrmData(),
	});
}

export async function getCrmData() {
	const queryClient = new QueryClient();
	return await queryClient.fetchQuery({
		queryKey: ['crmData'],
		queryFn: () => CrmService.getCrmData(),
	});
}
