import styled, { css } from 'styled-components';
import theme from 'styled-theming';
import { HEADER_HEIGHT } from '@atlas/ui-library/components/organisms/Header/style';
import { media } from '@atlas/ui-library/lib/breakpoints';

const themeSection = theme('variant', {
	light: `
		background-color: var(--color-basic-white);
	`,
	medium: `
		background-color: var(--color-neutral-_50);
		color: color-mix(
			in srgb,
			var(--color-text-onSurface)
				var(--opacity-emphasis-surface-medium),
			transparent
		);
	`,
	dark: `
		background-color: var(--color-primary-_900);
		color: var(--color-basic-white);
		h1,
		h2,
		h3,
		h4,
		p,
		em,
		strong,
		ol,
		li,
		span {
			color: var(--color-basic-white);
		}
	`,
});

const borderSection = theme('border', {
	true: `
		border: 1px solid var(--color-neutral-_100);
	`,
	false: `
		border: none;
	`,
});

const textAlignSection = theme('textAlign', {
	left: `
		text-align: left;
	`,
	center: `
		text-align: center;
	`,
	right: `
		text-align: right;
	`,
});

const heightSection = theme('height', {
	default: ``,
	full: `
		display: flex;
		flex: 1;
		align-items: center;
	`,
});

const widthSection = theme('width', {
	full: css`
		& > .wrap {
			width: 100%;
			max-width: 100%;
			${media.smMax`
				padding: 0;
			`}
		}
		padding: 0;
	`,
	container: css`
		${media.mdMin`
			padding: var(--spacing-_40) 0;
		`}
		${media.md`
			padding: var(--spacing-_32);
		`}
		${media.smMax`
			padding: var(--spacing-_24) var(--spacing-_16);
		`}
	`,
	containerNoPaddingTop: css`
		${media.mdMin`
		padding: 0 0 var(--spacing-_40);
	`}
		${media.md`
		padding: 0 var(--spacing-_32) var(--spacing-_32);
	`}
	${media.smMax`
		padding: 0 var(--spacing-_16) var(--spacing-_24);
	`}
	`,
	containerNoPadding: css`
		padding: 0;

		/** NOTE: This forces last section to respect a margin between it and footer. 
		* IMPORTANT: Excludes any FooterSC from this rule. 
		*/
		&:nth-last-of-type(2):not(:has(.FooterSC)) {
			margin-bottom: 64px;
		}
	`,
});

const SectionContent = styled.section`
	margin: 0 auto;
	width: 100%;
	background-color: white;
	position: relative;
	${widthSection}
	${themeSection}
	${textAlignSection}
	${borderSection}
	${heightSection}

	/* NOTE: This forces first section to respect Header height. */
	&:nth-of-type(2) {
		margin-top: ${HEADER_HEIGHT};
	}
`;

export { SectionContent };
