export {
	FILTER_COLLECTION,
	FILTER_DELIMITER,
} from '@atlas/statics/lib/replacePageDataVariables/config';

// CrmData props to exclude searching in.
export const EXCLUDED_PROPERTIES = [
	'availableLanguages',
	'eligibleCountries',
	'prices',
];
