import ContentLoader from 'react-content-loader';
import styled from 'styled-components';
import { media } from '@atlas/ui-library/lib/breakpoints';

const Mobile = styled(ContentLoader)`
	width: 100%;
	${media.smMin`
        display: none;
        `}
`;

const Desktop = styled(ContentLoader)`
	width: 100%;
	display: none;
	${media.mdMin`
        display: flex;
    `}
`;

export const InfoBlockSkeletonSC = {
	Mobile,
	Desktop,
};
