import React from 'react';
import { Body } from '@atlas/ui-library/components/atoms/FontScale/Body';
import { ComparativeTableProps } from './types';
import {
	CheckIcon,
	CrossIcon,
	TBody,
	TBodyCell,
	TBodyRow,
	TBodyRowHeader,
	TContainer,
	THead,
	THeadCell,
	THeadRow,
	TLayout,
} from './style';
export const ComparativeTable = ({
	headers,
	rows,
}: ComparativeTableProps) => {
	const getColumnIcon = (columnValue: 'YES' | 'NO') =>
		columnValue === 'YES' ? <CheckIcon /> : <CrossIcon />;

	return (
		<TContainer>
			<TLayout>
				<THead>
					<THeadRow>
						{headers?.map(({ body: { text } }) => (
							<THeadCell key={text}>
								<Body size="L" variant="light">
									{text}
								</Body>
							</THeadCell>
						))}
					</THeadRow>
				</THead>
				<TBody>
					{rows?.map(({ body: { text }, other, ours }) => (
						<TBodyRow key={text}>
							<TBodyRowHeader scope="row">
								<Body size="L" variant="light">
									{text}
								</Body>
							</TBodyRowHeader>
							<TBodyCell>
								{getColumnIcon(other)}
							</TBodyCell>
							<TBodyCell>{getColumnIcon(ours)}</TBodyCell>
						</TBodyRow>
					))}
				</TBody>
			</TLayout>
		</TContainer>
	);
};
