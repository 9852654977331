import { parseTextToSVG } from '@atlas/statics/lib/parseTextToSVG';
import { Font } from '@atlas/statics/lib/types/_fonts';

type applyImageFilterProps = {
	variableValue: string;
	font: Font;
};

export async function applyImageFilter({
	variableValue,
	font,
}: applyImageFilterProps) {
	try {
		return await parseTextToSVG(variableValue, font);
	} catch (e) {
		throw new Error('Failed to parse text to SVG.', { cause: e });
	}
}
