import type { Filter } from '@atlas/statics/lib/replacePageDataVariables/types';

// Available filters for text processing.
export const FILTER_COLLECTION: Filter[] = [
	'image', // Converts text to SVG
	'subdomain', // Replace url's with subdomains url's (Ex.: www.domain.com -> online.domain.com))
	'forceUrl', // Hardcodes 'window.location.host' value into url and email
];

// Delimiter between variable name and filter. Ex.: {{ variableName, image }}
export const FILTER_DELIMITER = ',';
