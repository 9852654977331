export const defaultLocale = 'en';

export const timeZone = 'Europe/Madrid';

// NOTE: ISO codes that need to be shortened by region (ex.: ja-JP -> jp)
export const localesByRegion = [
	'ja-jp',
	'zh-tw',
	'zh-cn',
	'ko-kr',
	'uk-ua',
];

/**
 * HACK: i18n deadline available locales.
 * @see https://leadtech.atlassian.net/browse/VISAS-49352
 */
export const activeKRLocales = ['en', 'fr'];
