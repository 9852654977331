import React, { useState } from 'react';
import Image from 'next/image';
import { useWindowWidth } from '@atlas/ui-library/hooks/useWindowWidth';
import type { LogoProps } from './types';
import { isMobile } from '@atlas/ui-library/lib/isMobile';

export const Logo = ({ image, isScrolled }: LogoProps) => {
	const [imgSrcError, setImgSrcError] = useState<null | string>(null);
	const windowWidth = useWindowWidth();
	const isMobileView = isMobile(windowWidth);

	const { alt, title } = image;
	const height = image.height ?? '40';
	const width = isMobileView ? '110' : image.width ?? '250';
	const baseSrc = isMobileView
		? image.src.toString().replace('lg', 'xs')
		: image.src;

	const hasInvertedSuffix = baseSrc
		.toString()
		.includes('_inverted.webp');

	const src =
		isMobileView && isScrolled
			? hasInvertedSuffix
				? baseSrc
				: baseSrc.toString().replace('.webp', '_inverted.webp')
			: hasInvertedSuffix
			  ? baseSrc.toString().replace('_inverted.webp', '.webp')
			  : baseSrc;

	return (
		<Image
			unoptimized
			src={imgSrcError ?? src}
			alt={alt}
			title={title}
			width={width}
			height={height}
			quality={100}
			onError={() => setImgSrcError(baseSrc as string)}
		/>
	);
};
