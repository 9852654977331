import styled, { css } from 'styled-components';
import theme from 'styled-theming';
import { BodyContent } from '../../atoms/FontScale/Body/style';
import { media } from '@atlas/ui-library/lib/breakpoints';

const alertStyle = theme('variant', {
	info: css`
		border: var(--color-information-_700) 1px solid;
		background: var(--color-information-_100);
		border-width: 2px;
		${BodyContent} *, svg {
			color: var(--color-information-_700);
		}
	`,
	success: css`
		border: var(--color-success-_500) 1px solid;
		background: var(--color-success-_100);
		border-width: 2px;
		${BodyContent} *, svg {
			color: var(--color-success-_500);
		}
	`,
	error: css`
		border: var(--color-error-_500) 1px solid;
		background: var(--color-error-_100);
		border-width: 2px;
		${BodyContent} *, svg {
			color: var(--color-error-_500);
		}
	`,
	warning: css`
		border: var(--color-warning-_500) 1px solid;
		background: var(--color-warning-_100);
		border-width: 2px;
		${BodyContent} *, svg {
			color: var(--color-warning-_500);
		}
	`,
});

export const BannerContent = styled.div`
	padding: var(--spacing-_16);
	line-height: 1.25em;
	align-items: center;
	justify-content: center;
	display: grid;
	grid-template-columns: auto;
	grid-template-rows: var(--spacing-_32) auto;
	justify-items: center;

	${media.mdMin`
		grid-template-columns: var(--spacing-_32) auto;
		grid-template-rows: auto;
	`}

	${BodyContent} {
		padding-top: var(--spacing-_16);
		${media.mdMin`
			padding: 0px 0px 0px var(--spacing-_8);
		`}

		p {
			margin-bottom: 0;
		}
	}

	svg {
		font-size: 2em;
	}

	${alertStyle};
`;
