import styled, { css } from 'styled-components';
import { media } from '@atlas/ui-library/lib/breakpoints';
import { TableLayout, TableSC } from '../Table/style';
import { BodyContent } from '@atlas/ui-library/components/atoms/FontScale/Body/style';
import { Body } from '@atlas/ui-library/components/atoms/FontScale/Body';

export const TContainer = styled(TableSC)`
	margin: var(--spacing-_40) 0;
`;

export const TScrollMsg = styled(Body)`
	font-style: italic;
`;

export const TLayoutContainer = styled(TableSC)`
	overflow-x: auto;
`;

export const TLayout = styled(TableLayout)``;

export const THead = styled.thead`
	text-align: center;
	${media.smMax`
        width: 66px;
        padding: 12px 4px;
    `}
`;

export const THeadRow = styled.tr`
	text-align: center;
`;

const HighlightTHeadCell = css`
	padding: 20px 16px;
	${BodyContent} {
		font-size: var(--font-body-size-L-mobile);
	}
`;

const RegularTHeadCell = css`
	padding: 8px 0px;
	${BodyContent} {
		font-size: var(--font-body-size-XS-mobile);
		${media.mdMin`
			font-size: inherit;
			line-height: inherit;
			letter-spacing: inherit;
		`}
	}
`;

export const THeadCell = styled.th<{ $highlight: boolean }>`
	background-color: var(--color-primary-_50);
	border: 1px solid var(--color-neutral-_300);

	${BodyContent} {
		p {
			margin-bottom: 0;
		}
		font-weight: 700;
		line-height: 20.8px;
		letter-spacing: 0.5px;
	}

	${({ $highlight }) =>
		$highlight ? HighlightTHeadCell : RegularTHeadCell};
`;

export const TBody = styled.tbody`
	text-align: center;
	&:first-child {
		text-align: left;
		border-left: 1px solid var(--color-primary-_900);
	}
`;

const HighlightTBodyRow = css`
	&:nth-child(odd) {
		background-color: var(--color-basic-white);
	}
	&:nth-child(even) {
		background-color: var(--color-neutral-_100);
	}
`;

export const TBodyRow = styled.tr<{ $highlight: boolean }>`
	text-align: center;
	&:first-child {
		text-align: left;
		border-left: 1px solid var(--color-primary-_900);
	}

	${({ $highlight }) => $highlight && HighlightTBodyRow}
`;

const Cell = css<{ $highlight: boolean }>`
	text-align: center;
	border: 1px solid var(--color-neutral-_300);
	padding: ${({ $highlight }) => ($highlight ? '16px' : '8px')} 0;
	${BodyContent} {
		p {
			margin-bottom: 0;
		}
	}
`;

export const TBodyRowHeader = styled.th<{ $highlight: boolean }>`
	${Cell}
`;

export const TBodyCell = styled.td<{ $highlight: boolean }>`
	${Cell}
`;
