type applyForceUrlFilterProps = {
	variableName: string;
	variableValue: string;
};

// FIXME: HACK: If 'forceUrl' filter is present hardcode URL
// with retrieved one from window.location.host
export function applyForceUrlFilter({
	variableName,
	variableValue,
}: applyForceUrlFilterProps): string {
	const hostUrl = window.location.host;
	if (variableName.toLowerCase().includes('url')) {
		return hostUrl;
	} else if (variableName.toLowerCase().includes('email')) {
		const emailName = variableValue.split('@').shift();
		const emailDomain = hostUrl.split(/(www|test)./).pop();
		return `${emailName}@${emailDomain}`;
	}
	return variableValue;
}
