export function applySubdomainFilter(variableValue: string) {
	/**
	 * FIXME: HACK: get Host from window.location instead of CRM data
	 * because Next is saving static values into a file called
	 * index.txt and that means multiple domains with the same
	 * theme share that data (we don't want that).*/
	const isStage = process.env!.NEXT_PUBLIC_APP_ENV === 'stage';
	const isProd = process.env!.NEXT_PUBLIC_APP_ENV === 'main';
	const host =
		isProd || isStage ? window.location.host : variableValue;

	const replacedValue =
		window.location.host.startsWith('test') ||
		window.location.host.startsWith('localhost')
			? 'test.online'
			: 'online';

	return host.replace(/www|test/g, replacedValue);
}
