import React from 'react';
import { Body } from '@atlas/ui-library/components/atoms/FontScale/Body';
import { RegularTableProps } from './types';
import {
	TContainer,
	TLayout,
	THead,
	TBody,
	THeadRow,
	THeadCell,
	TBodyCell,
	TBodyRow,
	TBodyRowHeader,
	TScrollMsg,
	TLayoutContainer,
} from './style';
import { isMobile } from '@atlas/ui-library/lib/isMobile';
import { useWindowWidth } from '@atlas/ui-library/hooks/useWindowWidth';

export const RegularTable = ({
	tableScrollLabel,
	headers,
	rows,
	highlight = false,
}: RegularTableProps) => {
	const windowWidth = useWindowWidth();
	const isMobileView = isMobile(windowWidth);

	return (
		<TContainer>
			{isMobileView && (
				<TScrollMsg variant="light" size="S">
					{tableScrollLabel}
				</TScrollMsg>
			)}
			<TLayoutContainer>
				<TLayout>
					<THead>
						<THeadRow>
							{headers?.map(({ body: { text } }) => (
								<THeadCell
									key={text}
									$highlight={highlight}>
									<Body size="L" variant="light">
										{text}
									</Body>
								</THeadCell>
							))}
						</THeadRow>
					</THead>
					<TBody>
						{rows?.map((row, i) => (
							<TBodyRow
								key={`tbody-tr-${i}`}
								$highlight={highlight}>
								{row.element?.map(({ text }) =>
									i === 0 ? (
										<TBodyRowHeader
											scope="row"
											key={text}
											$highlight={highlight}>
											<Body
												size="L"
												variant="light">
												{text}
											</Body>
										</TBodyRowHeader>
									) : (
										<TBodyCell
											key={text}
											$highlight={highlight}>
											<Body
												size="L"
												variant="light">
												{text}
											</Body>
										</TBodyCell>
									),
								)}
							</TBodyRow>
						))}
					</TBody>
				</TLayout>
			</TLayoutContainer>
		</TContainer>
	);
};
