import TextToSVG from '@npm_leadtech/jsr-text-to-svg';
import { Font } from '@atlas/statics/lib/types/_fonts';

const FONTS_FOLDER = `/common/fonts`;
const DEFAULT_FONTSIZE = '16';

export const parseTextToSVG = async (
	text: string,
	font: Font,
): Promise<string> => {
	const [fontFilename] = font.body.family.regular.split(', ');
	const [fontSize] = /\d+/.exec(font.body.size.L.mobile!) || [
		DEFAULT_FONTSIZE,
	];
	const SVGparser = new TextToSVG({
		font: `${FONTS_FOLDER}/${fontFilename}.ttf`,
		fontSize,
		options: {
			letterSpacing: 0,
		},
	});
	return await SVGparser.toSVG(text).catch((err: unknown) => {
		throw new Error(
			`SVGParser.toSVG() failed parsing '${text}' to SVG.`,
			{
				cause: err,
			},
		);
	});
};
