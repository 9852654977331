import { Elements } from '@atlas/ui-library/components/layout/Section/types';
import { useWindowWidth } from '@atlas/ui-library/hooks/useWindowWidth';
import { InfoBlockProps } from './types';
import { isMobile } from '@atlas/ui-library/lib/isMobile';

export const useInfoBlock = ({
	add_element,
	variant,
}: Pick<InfoBlockProps, 'add_element' | 'variant'>) => {
	const windowWidth = useWindowWidth();
	const isMobileView = isMobile(windowWidth);

	const sortElements = (
		elements: Elements[],
		sortOrder: Record<Elements['type'], number>,
	) => {
		return elements.sort(
			(a, b) => sortOrder[a.type] - sortOrder[b.type],
		);
	};

	const elements = add_element.map(
		({ element = [], direction, verticalAlign = 'center' }) => {
			const el: Elements[] = JSON.parse(JSON.stringify(element));
			return {
				element: el,
				direction,
				verticalAlign,
			};
		},
	);

	if (isMobileView) {
		if (variant === 'titleTextImageButton') {
			const sortOrder = { title: 1, body: 2, image: 3, link: 4 };
			const newArray = elements.flatMap(({ element }) => element);
			return [
				{
					element: sortElements(newArray, sortOrder),
					verticalAlign: 'center',
					direction: 'column',
				},
			];
		} else if (variant === 'titleText') {
			const sortOrder = { title: 1, body: 2 };
			const newArray = elements
				.flatMap(({ element }) => element)
				.filter(({ type }) =>
					Object.keys(sortOrder).includes(type),
				);
			return [
				{
					element: sortElements(newArray, sortOrder),
					verticalAlign: 'center',
					direction: 'column',
				},
			];
		}
	}

	return elements;
};
