import { AbstractIntlMessages, useMessages } from 'next-intl';
import { base64Encode } from '@atlas/statics/lib/base64Encoder';
import { Elements } from '@atlas/ui-library/components/layout/Section/types';

export const PROPS_TO_TRANSLATE = [
	'alt',
	'text',
	'metaTitle',
	'metaDescription',
	'metaOgDescription',
	'metaOgTitle',
	'metaOgSiteName',
	'openedLabel',
	'closedLabel',
	'collapsedLabel',
	'expandedLabel',
	'linkText',
	'inputPlaceholder',
	'tableScrollLabel',
];

const translateString = ({
	str,
	translate,
	key,
}: {
	str: string;
	translate: AbstractIntlMessages;
	key?: string;
}): string | AbstractIntlMessages => {
	return !!key && PROPS_TO_TRANSLATE.includes(key)
		? translate[base64Encode(str)]
		: str;
};

const translateIterateArray = ({
	arr,
	translate,
}: {
	arr: any[];
	translate: AbstractIntlMessages;
}): any[] => {
	return arr.map((item) =>
		translateElement({
			element: item,
			translate,
		}),
	);
};

const translateIterateObject = ({
	obj,
	translate,
}: {
	obj: Record<string, any>;
	translate: AbstractIntlMessages;
}): Record<string, any> => {
	const result: Record<string, any> = {};
	for (const key in obj) {
		result[key] = translateElement({
			element: obj[key],
			translate,
			key,
		});
	}
	return result;
};

const translateElement = ({
	element,
	translate,
	key,
}: {
	element: Record<string, any>;
	translate: AbstractIntlMessages;
	key?: string;
}): Record<string, any> | string => {
	if (typeof element === 'string') {
		return key
			? translateString({
					str: element,
					translate,
					key,
			  })
			: translateString({ str: element, translate });
	} else if (Array.isArray(element)) {
		return translateIterateArray({
			arr: element,
			translate,
		});
	} else if (typeof element === 'object' && element !== null) {
		return translateIterateObject({
			obj: element,
			translate,
		});
	}
	return element;
};

export function useTranslateElements(elements: Elements[]) {
	const t = useMessages();

	const translatedElements = elements.map((element: Elements) => {
		return translateElement({ element, translate: t });
	});

	return translatedElements as Elements[];
}
