import type { Elements } from './types';
import { useCrmData } from '@atlas/statics/hooks/useCrmData';
import { useProcessedElements } from '@atlas/statics/hooks/useProcessedElements';
import { useTranslateElements } from '@atlas/statics/hooks/useTranslateElements';

type UseSectionProps = {
	elements: Elements[];
	sectionId: string;
};

type UseSectionResponse = {
	processedElements: Elements[];
	isLoading: boolean;
};

export const useSection = ({
	elements,
	sectionId,
}: UseSectionProps): UseSectionResponse => {
	const { data: crmData } = useCrmData();
	const translatedElements = useTranslateElements(elements);
	const { data: processedElements, isLoading } = useProcessedElements(
		{
			elements: translatedElements,
			sectionId,
			crmData,
		},
	);

	return { processedElements: processedElements || [], isLoading };
};
