import React from 'react';
import { TableProps } from './types';
import { ComparativeTable } from '@atlas/ui-library/components/organisms/ComparativeTable';
import { RegularTable } from '../RegularTable';
import { Skeleton } from '@atlas/ui-library/components/layout/Skeleton';
import { TableSkeleton } from './skeleton';

/**
 * Component for showing Table.
 * @component
 * @param titlesColumn Column headers
 * @param variant Table variants. 'COMPARATIVE' | 'TABLE' | 'HIGHLIGHT'
 * @param comparativeTableRow Content rows for 'COMPARATIVE' variant
 * @param regularTableRow Content rows for 'TABLE' variant
 */
const Table = ({
	tableScrollLabel,
	titlesColumn = [],
	comparativeTableRow = [],
	variant = 'REGULAR',
	regularTableRow = [],
	isLoading = false,
}: TableProps) => {
	const tableVariants = {
		COMPARATIVE: (
			<ComparativeTable
				headers={titlesColumn}
				rows={comparativeTableRow}
			/>
		),
		REGULAR: (
			<RegularTable
				headers={titlesColumn}
				rows={regularTableRow}
				tableScrollLabel={tableScrollLabel}
			/>
		),
		HIGHLIGHT: (
			<RegularTable
				headers={titlesColumn}
				rows={regularTableRow}
				highlight={true}
				tableScrollLabel={tableScrollLabel}
			/>
		),
	};

	if (isLoading)
		return <Skeleton renderSkeleton={() => <TableSkeleton />} />;

	return tableVariants[variant];
};

export { Table };
